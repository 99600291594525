import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { getCache } from './cache';
import createErrorLink from './links/createErrorLink';

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const createClient = () => {
  const authLink = setContext((_, { headers }) => {
    const { currency } = globalApolloClient.extract();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        'Access-Control-Allow-Credentials': true,
        'X-CURRENCY': currency || 'USD',
        ...headers,
      },
    };
  });

  const batchHttpLink = new BatchHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API,
    credentials: 'include',
  });

  const errorLink = createErrorLink();

  return new ApolloClient({
    connectToDevTools: !!process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      new RetryLink({
        attempts: { max: 1 },
      }),
      errorLink,
      authLink.concat(batchHttpLink),
    ]),
    cache: getCache(),
  });
};

export const client = (
  initialState: Record<string, unknown> = {}
): ApolloClient<NormalizedCacheObject> => {
  const apolloClient = globalApolloClient ?? createClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    const data = { ...existingCache, ...initialState };
    apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return apolloClient;
  // Create the Apollo Client once in the client
  if (!globalApolloClient) globalApolloClient = apolloClient;

  return apolloClient;
};

export const useApollo = (
  initialState: Record<string, unknown> = {}
): ApolloClient<NormalizedCacheObject> => {
  const store = useMemo(() => client(initialState), [initialState]);
  return store;
};

export default client;
